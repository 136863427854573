import { supabase } from 'src/supabase'
import { EntityAPI, EntityAPIFeatures } from 'src/api/_EntityAPI'
import * as entityTypes from 'src/types/entities/'
import { Address, ChannelMessage, ChannelMessageInput, FeeType, PRBillingReviewStatus, BillableExpenseByResource, NotificationStatus, TaskStatus, PTOReset } from 'src/types'
import { useUserStore } from 'src/stores/user'
import * as storageApi from 'src/api/storage'
import { currentDateAsString, dateToString } from 'src/utils'

export const activeSubscriptions = new EntityAPI<entityTypes.ActiveSubscription, entityTypes.ActiveSubscriptionInput, number, 'active_subscriptions'>('active_subscriptions', 'id')
export const approvers = new EntityAPI<entityTypes.Approver, entityTypes.ApproverInput, number, 'approvers'>('approvers', 'id', '*, employee:employees ( profile:profiles!employees_id_fkey (*) )')
export const bids = new EntityAPI<entityTypes.Bid, entityTypes.BidInput, number, 'bids'>('bids', 'id')
export const bidRequests = new EntityAPI<entityTypes.BidRequest, entityTypes.BidRequestInput, number, 'bid_requests'>('bid_requests', 'id', '*, bids (*), opportunity:opportunities!bid_requests_requestor_opp_id_fkey (name), bidder_opp:opportunities!opportunities_bid_request_id_fkey(*)')
export const billingCycles = new EntityAPI<entityTypes.BillingCycle, entityTypes.BillingCycleInput, number, 'billing_cycles'>('billing_cycles', 'id', '*')
export const billingRateTables = new EntityAPI<entityTypes.BillingRateTable, entityTypes.BillingRateTableInput, number, 'billing_rate_tables'>('billing_rate_tables', 'id', '*, labor_rates (*), consultant_rates (*, vendor:vendors (*))')
export const brands = new EntityAPI<entityTypes.Brand, entityTypes.BrandInput, number, 'brands'>('brands', 'id', '*, locations:brand_locations ( * )')
export const brandLocations = new EntityAPI<entityTypes.BrandLocation, entityTypes.BrandLocationInput, number, 'brand_locations'>('brand_locations', 'id')
export const bugReports = new EntityAPI<entityTypes.BugReport, entityTypes.BugReportInput, number, 'bug_reports'>('bug_reports', 'id')
export const channelMemberships = new EntityAPI<entityTypes.ChannelMembership, entityTypes.ChannelMembershipInput, number, 'channel_memberships'>('channel_memberships', 'id')
export const chartOfAccounts = new EntityAPI<entityTypes.ChartOfAccount, entityTypes.ChartOfAccountInput, number, 'chart_of_accounts'>('chart_of_accounts', 'id')
export const clarifications = new EntityAPI<entityTypes.Clarification, entityTypes.ClarificationInput, number, 'clarifications'>('clarifications', 'id')
export const consultantRates = new EntityAPI<entityTypes.ConsultantRate, entityTypes.ConsultantRateInput, number, 'consultant_rates'>('consultant_rates', 'id', '*, vendor:vendors (*)')
export const contacts = new EntityAPI<entityTypes.Contact, entityTypes.ContactInput, number, 'contacts'>('contacts', 'id', '* , client:clients (name, account_no)', [EntityAPIFeatures.fts, EntityAPIFeatures.softDelete])
export const conversationMessages = new EntityAPI<entityTypes.ConversationMessage, entityTypes.ConversationMessageInput, number, 'conversation_messages'>('conversation_messages', 'id')
export const conversations = new EntityAPI<entityTypes.Conversation, entityTypes.ConversationInput, number, 'conversations'>('conversations', 'id')
//  (*, roles:user_roles(*, role:roles(*)))
export const crossTenantEmployees = new EntityAPI<entityTypes.CrossTenantEmployee, entityTypes.CrossTenantEmployeeInput, number, 'cross_tenant_employees'>('cross_tenant_employees', 'id', '*, employee:employees ( *, profile:profiles!employees_id_fkey (*, roles:user_roles(*, role:roles(*))) ), to_tenant:to_tenant_id (*), tenant:tenant_id (*)')
export const departments = new EntityAPI<entityTypes.Department, entityTypes.DepartmentInput, number, 'departments'>('departments', 'id', '*', [EntityAPIFeatures.softDelete])
export const emails = new EntityAPI<entityTypes.Email, entityTypes.EmailInput, number, 'emails'>('emails', 'id', '*')
export const emailSignatures = new EntityAPI<entityTypes.EmailSignature, entityTypes.EmailSignatureInput, number, 'email_signatures'>('email_signatures', 'id', '*')
export const emailNotifications = new EntityAPI<entityTypes.EmailNotification, entityTypes.EmailNotificationInput, number, 'email_notifications'>('email_notifications', 'id')
export const employees = new EntityAPI<entityTypes.Employee, entityTypes.EmployeeInput, string, 'employees'>('employees', 'id', '*, profile:profiles!employees_id_fkey (*, roles:user_roles(*, role:roles(*))), hr_info:employees_hr_info(*), employment_periods:employment_periods(*)', [EntityAPIFeatures.fts, EntityAPIFeatures.softDelete])
export const employeesHRInfo = new EntityAPI<entityTypes.EmployeeHRInfo, entityTypes.EmployeeHRInfoInput, string, 'employees_hr_info'>('employees_hr_info', 'id')
export const employmentPeriods = new EntityAPI<entityTypes.EmploymentPeriod, entityTypes.EmploymentPeriodInput, string, 'employment_periods'>('employment_periods', 'id')
export const expensePaymentMethods = new EntityAPI<entityTypes.ExpensePaymentMethod, entityTypes.ExpensePaymentMethodInput, number, 'expense_payment_methods'>('expense_payment_methods', 'id')
export const floatingHolidayHistory = new EntityAPI<entityTypes.FloatingHolidayHistory, entityTypes.FloatingHolidayHistoryInput, number, 'floating_holiday_history'>('floating_holiday_history', 'id')
export const holidays = new EntityAPI<entityTypes.Holiday, entityTypes.HolidayInput, number, 'holidays'>('holidays', 'id')
export const industries = new EntityAPI<entityTypes.Industry, entityTypes.IndustryInput, number, 'industries'>('industries', 'code')
export const jobTitles = new EntityAPI<entityTypes.JobTitle, entityTypes.JobTitleInput, number, 'job_titles'>('job_titles', 'id')
export const laborRates = new EntityAPI<entityTypes.LaborRate, entityTypes.LaborRateInput, number, 'labor_rates'>('labor_rates', 'id')
export const locations = new EntityAPI<entityTypes.Location, entityTypes.LocationInput, number, 'locations'>('locations', 'id')
export const mteAllocations = new EntityAPI<entityTypes.MTEAllocation, entityTypes.MTEAllocationInput, number, 'mte_allocations'>('mte_allocations', 'id')
export const oppApprovals = new EntityAPI<entityTypes.OppApproval, entityTypes.OppApprovalInput, number, 'opp_approvals'>('opp_approvals', 'id')
export const oppClarifications = new EntityAPI<entityTypes.OppClarification, entityTypes.OppClarificationInput, number, 'opp_clarifications'>('opp_clarifications', 'id')
export const oppOOMs = new EntityAPI<entityTypes.OppOOM, entityTypes.OppOOMInput, number, 'opp_orders_of_magnitude'>('opp_orders_of_magnitude', 'id')
export const oppRemarks = new EntityAPI<entityTypes.OppRemark, entityTypes.OppRemarkInput, number, 'opp_remarks'>('opp_remarks', 'id')
export const organization = new EntityAPI<entityTypes.Organization, entityTypes.OrganizationInput, number, 'organization'>('organization', 'id')
export const payScales = new EntityAPI<entityTypes.PayScale, entityTypes.PayScaleInput, number, 'pay_scales'>('pay_scales', 'id')
export const payments = new EntityAPI<entityTypes.Payment, entityTypes.PaymentInput, number, 'payments'>('payments', 'id', '*')
export const permissions = new EntityAPI<entityTypes.Permission, entityTypes.PermissionInput, number, 'permissions'>('permissions', 'id', '*, resource:resource_id (*)', undefined, { centralized: true })
export const phaseTemplates = new EntityAPI<entityTypes.PhaseTemplate, entityTypes.PhaseTemplateInput, number, 'phase_templates'>('phase_templates', 'id')
export const phases = new EntityAPI<entityTypes.Phase, entityTypes.PhaseInput, number, 'phases'>('phases', 'id')
export const priorBilledExpenses = new EntityAPI<entityTypes.PriorBilledExpense, entityTypes.PriorBilledExpenseInput, number, 'prior_billed_expenses'>('prior_billed_expenses', 'id')
export const ptoBalanceHistory = new EntityAPI<entityTypes.PTOBalanceHistory, entityTypes.PTOBalanceHistoryInput, number, 'pto_balance_history'>('pto_balance_history', 'id', '*, ptoBalance:pto_balances(employee_id)')
export const prBillings = new EntityAPI<entityTypes.PRBilling, entityTypes.PRBillingInput, number, 'pr_billings'>('pr_billings', 'id', '*, reviews:pr_billing_reviews (*)')
export const preBillingPICDelegates = new EntityAPI<entityTypes.PreBillingPICDelegate, entityTypes.PreBillingPICDelegateInput, number, 'pre_billing_pic_delegates'>('pre_billing_pic_delegates', 'id', '*, employee:employee_id ( profile:profiles!employees_id_fkey (*) )')
export const projectEmails = new EntityAPI<entityTypes.ProjectEmail, entityTypes.ProjectEmailInput, number, 'project_emails'>('project_emails', 'id', '*, email:emails (*), project:projects (name, number, id)')
export const projectExpenses = new EntityAPI<entityTypes.ProjectExpense, entityTypes.ProjectExpenseInput, number, 'project_expenses'>('project_expenses', 'id', '*, employee:employee_id (department_id, profile:profiles!employees_id_fkey (name))')
export const projectPhaseGroups = new EntityAPI<entityTypes.ProjectPhaseGroup, entityTypes.ProjectPhaseGroupInput, number, 'project_phase_groups'>('project_phase_groups', 'id')
export const projectPhaseResources = new EntityAPI<entityTypes.ProjectPhaseResource, entityTypes.ProjectPhaseResourceInput, number, 'project_phase_resources'>('project_phase_resources', 'id', '*, department:departments (*), pr_percent_completes (*), prior_billed_expenses (*), bid_requests (*)')
export const projectPhases = new EntityAPI<entityTypes.ProjectPhase, entityTypes.ProjectPhaseInput, number, 'project_phases'>('project_phases', 'id', '*, phase:phases (*), resources:project_phase_resources (*, department:departments (*)), phase_group:project_phase_groups (name)')
export const projectResourcePercentCompletes = new EntityAPI<entityTypes.PRPercentComplete, entityTypes.PRPercentCompleteInput, number, 'pr_percent_completes'>('pr_percent_completes', 'id')
export const projectResourceProjectionCompletes = new EntityAPI<entityTypes.PRProjection, entityTypes.PRProjectionInput, number, 'pr_projections'>('pr_projections', 'id')
export const projectResourceStatuses = new EntityAPI<entityTypes.ProjectResourceStatus, entityTypes.ProjectResourceStatusInput, string, 'project_resource_statuses'>('project_resource_statuses', 'status')
export const projectResourceProjectionSnapshotRecords = new EntityAPI<entityTypes.PRProjectionSnapshotRecord, entityTypes.PRProjectionSnapshotRecordInput, number, 'pr_projection_snapshot_records'>('pr_projection_snapshot_records', 'id')
export const projectSchedules = new EntityAPI<entityTypes.ProjectSchedule, entityTypes.ProjectScheduleInput, number, 'project_schedules'>('project_schedules', 'id')
export const projectScheduleTasks = new EntityAPI<entityTypes.ProjectScheduleTask, entityTypes.ProjectScheduleTaskInput, number, 'project_schedule_tasks'>('project_schedule_tasks', 'id')
export const projectTaskAssignments = new EntityAPI<entityTypes.ProjectTaskAssignment, entityTypes.ProjectTaskAssignmentInput, number, 'project_task_assignments'>('project_task_assignments', 'id')
export const projectTaskClarifications = new EntityAPI<entityTypes.ProjectTaskClarification, entityTypes.ProjectTaskClarificationInput, number, 'project_task_clarifications'>('project_task_clarifications', 'id', '*, employee:employee_id (department_id, profile:profiles!employees_id_fkey (name))')
export const projectTaskEstimates = new EntityAPI<entityTypes.ProjectTaskEstimate, entityTypes.ProjectTaskEstimateInput, number, 'project_task_estimates'>('project_task_estimates', 'id')
export const projectTaskExceptions = new EntityAPI<entityTypes.ProjectTaskException, entityTypes.ProjectTaskExceptionInput, number, 'project_task_exceptions'>('project_task_exceptions', 'id')
export const projectTaskExpenses = new EntityAPI<entityTypes.ProjectTaskExpense, entityTypes.ProjectTaskExpenseInput, number, 'project_task_expenses'>('project_task_expenses', 'id', '*, expense_account:expense_accounts (*), employee:employee_id (department_id, profile:profiles!employees_id_fkey (name))')
export const projectPhaseResourceProgressEstimates = new EntityAPI<entityTypes.ProjectPhaseResourceProgressEstimate, entityTypes.ProjectPhaseResourceProgressEstimateInput, number, 'project_phase_resource_progress_estimates'>('project_phase_resource_progress_estimates', 'id')
export const projectTemplates = new EntityAPI<entityTypes.ProjectTemplate, entityTypes.ProjectTemplateInput, number, 'project_templates'>('project_templates', 'id')
export const proposalDefaultSections = new EntityAPI<entityTypes.ProposalDefaultSection, entityTypes.ProposalDefaultSectionInput, number, 'proposal_default_sections'>('proposal_default_sections', 'id')
export const resources = new EntityAPI<entityTypes.Resource, entityTypes.ResourceInput, number, 'resources'>('resources', 'id', '*', undefined, { centralized: true })
export const roles = new EntityAPI<entityTypes.Role, entityTypes.RoleInput, number, 'roles'>('roles', 'role_slug')
export const rolePermissions = new EntityAPI<entityTypes.RolePermission, entityTypes.RolePermissionInput, number, 'role_permissions'>('role_permissions', 'id', '*, permissions:permission_id (*)')
export const ofccRFQs = new EntityAPI<entityTypes.OFCCRFQ, entityTypes.OFCCRFQInput, number, 'ofcc_rfqs'>('ofcc_rfqs', 'id', '*', [EntityAPIFeatures.fts])
export const settings = new EntityAPI<entityTypes.Setting, entityTypes.SettingInput, string, 'settings'>('settings', 'key')
export const storageFolders = new EntityAPI<entityTypes.StorageFolder, entityTypes.StorageFolderInput, number, 'storage_folders'>('storage_folders', 'id')
export const timesheetOverheads = new EntityAPI<entityTypes.TimesheetOverhead, entityTypes.TimesheetOverheadInput, number, 'timesheet_overheads'>('timesheet_overheads', 'id', '*', [EntityAPIFeatures.softDelete])
export const userRoles = new EntityAPI<entityTypes.UserRole, entityTypes.UserRoleInput, number, 'user_roles'>('user_roles', 'id', '*, role:roles (*)')
export const userSettings = new EntityAPI<entityTypes.UserSetting, entityTypes.UserSettingInput, string, 'user_settings'>('user_settings', 'key')
export const vendors = new EntityAPI<entityTypes.Vendor, entityTypes.VendorInput, number, 'vendors'>('vendors', 'id', '*', [EntityAPIFeatures.softDelete, EntityAPIFeatures.fts])

export const projectActivationApprovals = new EntityAPI<entityTypes.ProjectActivationApproval, entityTypes.ProjectActivationApprovalInput, number, 'project_activation_approvals'>('project_activation_approvals', 'id', '*')
export const projectActivationSteps = new EntityAPI<entityTypes.ProjectActivationStep, entityTypes.ProjectActivationStepInput, number, 'project_activation_steps'>('project_activation_steps', 'id', '*, approvers:project_activation_approvers (*)')
export const projectActivationApprovers = new EntityAPI<entityTypes.ProjectActivationApprover, entityTypes.ProjectActivationApproverInput, number, 'project_activation_approvers'>('project_activation_approvers', 'id', '*')

type PRBillingAndReview = { pr_billing: entityTypes.PRBilling, pr_billing_review: entityTypes.PRBillingReview }

// PTO Resets
class PTOResetsAPI extends EntityAPI<entityTypes.PTOReset, entityTypes.PTOResetInput, number, 'pto_resets'> {
  constructor() {
    super('pto_resets', 'id')
  }

  async resetAnnualBalancesForAllEmployees(year: number, floatingHolidayTotal: number, resetBy: string): Promise<{ data: PTOReset, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('reset_time_off_balances_for_year', { arg_year: year, arg_floating_holiday_total: floatingHolidayTotal, arg_reset_by: resetBy })
    if (error) return { error: error.message }
    else return { data: data as unknown as PTOReset }
  }
}
export const ptoResets = new PTOResetsAPI()

// PR Billing Reviews
class PRBillingReviewsAPI extends EntityAPI<entityTypes.PRBillingReview, entityTypes.PRBillingReviewInput, number, 'pr_billing_reviews'> {
  constructor() {
    super('pr_billing_reviews', 'id')
  }

  async addReview(employeeId: string, prBilling: { projectId: number, projectPhaseId: number, resourceId: number, projectTaskId?: number, feeType: FeeType, amount: number, month: number, year: number, percentOfBudget: number, expenseAccountId?: number, projectExpenseType?: 'included' | 'reimbursable', taskIds?: number[], expenseIds?: number[], minutes?: number }): Promise<{ data: PRBillingAndReview, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('add_pr_billing_review', {
      employee_id: employeeId,
      project_id: prBilling.projectId,
      project_phase_id: prBilling.projectPhaseId,
      exp_acc_id: prBilling.expenseAccountId,
      resource_id: prBilling.resourceId,
      fee_type: prBilling.feeType,
      amount: prBilling.amount,
      month: prBilling.month,
      year: prBilling.year,
      percent_of_budget: prBilling.percentOfBudget,
      project_task_id: prBilling.projectTaskId,
      pr_expense_type: prBilling.projectExpenseType,
      ts_entry_ids: prBilling.taskIds,
      emp_expense_ids: prBilling.expenseIds,
      minutes: prBilling.minutes
    })
    if (error) return { error: error.message }
    else return { data: data as unknown as PRBillingAndReview }
  }

  async deleteReview(prBillingReviewId: number): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('remove_pr_billing_review', { p_pr_billing_review_id: prBillingReviewId })
    if (error) return { error: error.message }
    else return {}
  }
}

export const prBillingReviews = new PRBillingReviewsAPI()

// Expense Accounts

class ExpenseAccountsAPI extends EntityAPI<entityTypes.ExpenseAccount, entityTypes.ExpenseAccountInput, number, 'expense_accounts'> {
  constructor() {
    super('expense_accounts', 'id', '*')
  }

  async getFromProjectResource(projectResourceId: number): Promise<{ data: entityTypes.ExpenseAccount[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_expense_accounts_from_project_resource', { project_phase_resource_id: projectResourceId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.ExpenseAccount[] }
  }
}
export const expenseAccounts = new ExpenseAccountsAPI()

// Invoices
class InvoicesAPI extends EntityAPI<entityTypes.Invoice, entityTypes.InvoiceInput, number, 'invoices'> {
  constructor() {
    super('invoices', 'id', '*', [EntityAPIFeatures.fts])
  }

  async create(newInvoice: { projectId: number, invoiceNumber: number, invoiceDate: string, dueDate: string, contactId: number, ccIds?: number[], pastDue?: number }): Promise<{ data: entityTypes.Invoice, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_invoice', { pid: newInvoice.projectId, inv_number: newInvoice.invoiceNumber, inv_date: newInvoice.invoiceDate, due: newInvoice.dueDate, contact_id: newInvoice.contactId, cc_ids: newInvoice.ccIds || undefined, past_due: newInvoice.pastDue || 0 })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Invoice }
  }

  async getNextInvoiceNumber(): Promise<number> {
    const { data, error } = await supabase.from(this.tableName).select('invoice_number').order('invoice_number', { ascending: false }).limit(1)
    if (!error && data && data.length) {
      return data[0].invoice_number + 1
    } else {
      return 1000
    }
  }

  async delete(id: number): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('delete_invoice', { invoice_id_to_delete: id })
    if (error) return { error: error.message }
    else return {}
  }

  async getTotals(): Promise<{ data: { total_late_amount: number, total_outstanding_amount: number }, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('calculate_invoice_totals')
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as { total_late_amount: number, total_outstanding_amount: number } }
  }
}

export const invoices = new InvoicesAPI()

interface ProjectTaskMinutes {
  project_task_id: number
  total_minutes: number
  estimated_minutes: number
  remaining_minutes: number
}

// Project Tasks
class ProjectTasksAPI extends EntityAPI<entityTypes.ProjectTask, entityTypes.ProjectTaskInput, number, 'project_tasks'> {
  constructor() {
    super('project_tasks', 'id', `*,
    estimates:project_task_estimates (
      employee:employee_id,
      fee,
      hours,
      hourly_rate,
      comments,
      created_at
    ),
    expenses:project_task_expenses (*),
    exceptions:project_task_exceptions (*),
    clarifications:project_task_clarifications (*)`)
  }

  async getMinutes(projectTaskId: number): Promise<{ data: ProjectTaskMinutes, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_project_task_minutes', { pt_id: projectTaskId })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as ProjectTaskMinutes }
  }

  async getForProjectPhase(projectPhaseId: number): Promise<{ data: entityTypes.ProjectTask[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_project_tasks_by_project_phase', { phase_id: projectPhaseId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.ProjectTask[] }
  }

  async getForProjectResource(projectResourceId: number): Promise<{ data: entityTypes.ProjectTask[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_project_tasks_by_project_phase_resource', { resource_id: projectResourceId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.ProjectTask[] }
  }

  async merge(taskIdsToMerge: number[], taskIdToMergeInto: number, newTaskName: string, newTaskStatus: TaskStatus): Promise<{ data: entityTypes.ProjectTask, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('merge_tasks', { task_ids_to_merge: taskIdsToMerge, task_id_to_merge_into: taskIdToMergeInto, new_task_name: newTaskName, new_task_status: newTaskStatus })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.ProjectTask }
  }
}

export const projectTasks = new ProjectTasksAPI()

// Project Resource Projection Snapshots
class ProjectResourceProjectionSnapshotsAPI extends EntityAPI<entityTypes.PRProjectionSnapshot, entityTypes.PRProjectionSnapshotInput, number, 'pr_projection_snapshots'> {
  constructor() {
    super('pr_projection_snapshots', 'id', `*,
    records:pr_projection_snapshot_records (*)`)
  }

  // CREATE OR REPLACE FUNCTION get_most_recent_project_snapshot()
  async getMostRecent(): Promise<{ data: entityTypes.PRProjectionSnapshot, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_most_recent_project_snapshot')
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PRProjectionSnapshot }
  }
}

export const projectResourceProjectionSnapshots = new ProjectResourceProjectionSnapshotsAPI()
// PTO Balances
class PTOBalancesAPI extends EntityAPI<entityTypes.PTOBalance, entityTypes.PTOBalanceInput, number, 'pto_balances'> {
  constructor() {
    super('pto_balances', 'id')
  }

  async resetAll(): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('reset_pto_balances')
    if (error) return { error: error.message }
    else return {}
  }
}

export const ptoBalances = new PTOBalancesAPI()

// Floating Holiday Balances
class FloatingHolidayBalancesAPI extends EntityAPI<entityTypes.FloatingHolidayBalance, entityTypes.FloatingHolidayBalanceInput, number, 'floating_holiday_balances'> {
  constructor() {
    super('floating_holiday_balances', 'id')
  }

  async resetAll(days: number): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('reset_floating_holiday_balances', { days })
    if (error) return { error: error.message }
    else return {}
  }
}
export const floatingHolidayBalances = new FloatingHolidayBalancesAPI()

// Floating Holiday Requests
class FloatingHolidayRequestsAPI extends EntityAPI<entityTypes.FloatingHolidayRequest, entityTypes.FloatingHolidayRequestInput, number, 'floating_holiday_requests'> {
  constructor() {
    super('floating_holiday_requests', 'id')
  }

  async getPendingAndApprovedBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('floating_holiday_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate)).in('status', ['pending', 'approved'])
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }

  async getApprovedAndDeniedBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('floating_holiday_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate)).in('status', ['approved', 'denied'])
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }

  async getBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('floating_holiday_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }

  async getForCurrentYear(employeeId: string): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('floating_holiday_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(new Date((new Date()).getFullYear(), 0, 1))).lte('date', dateToString(new Date((new Date()).getFullYear(), 11, 31)))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }

  async getForYear(employeeId: string, year: number): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('floating_holiday_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(new Date(year, 0, 1))).lte('date', dateToString(new Date(year, 11, 31)))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }

  async countTotalPendingDays(employeeId: string): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('count_total_pending_floating_holidays', { employee_id: employeeId })
    if (error) return { error: error.message }
    else return { data }
  }

  async countTotalPendingDaysForYear(employeeId: string, year: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('count_total_pending_floating_holidays_for_year', { employee_id: employeeId, year })
    if (error) return { error: error.message }
    else return { data }
  }

  async countTotalApprovedDaysForYear(employeeId: string, year: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('count_total_approved_floating_holidays_for_year', { employee_id: employeeId, year })
    if (error) return { error: error.message }
    else return { data }
  }

  async approve(requestId: number, approverId: string): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('approve_floating_holiday_request', { fh_request_id: requestId, approver_id: approverId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }

  async deny(requestId: number, reviewerId: string, reason?: string): Promise<{ data: entityTypes.FloatingHolidayRequest[], error?: undefined } | { data?: undefined, error: string }> {
    reason = reason || 'No reason'
    const { data, error } = await supabase.rpc('deny_floating_holiday_request', { floating_holiday_request_id: requestId, reviewer_id: reviewerId, reason })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.FloatingHolidayRequest[] }
  }
}

export const floatingHolidayRequests = new FloatingHolidayRequestsAPI()

// PTO Requests
class PTORequestsAPI extends EntityAPI<entityTypes.PTORequest, entityTypes.PTORequestInput, number, 'pto_requests'> {
  constructor() {
    super('pto_requests', 'id')
  }

  async getPendingAndApprovedBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('pto_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate)).in('status', ['pending', 'approved'])
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }

  async getApprovedAndDeniedBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('pto_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate)).in('status', ['approved', 'denied'])
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }

  async getBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('pto_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }

  async getForCurrentYear(employeeId: string): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('pto_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(new Date((new Date()).getFullYear(), 0, 1))).lte('date', dateToString(new Date((new Date()).getFullYear(), 11, 31)))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }

  async getForYear(employeeId: string, year: number): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('pto_requests').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(new Date(year, 0, 1))).lte('date', dateToString(new Date(year, 11, 31)))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }

  async countTotalPendingMinutes(employeeId: string): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('count_total_pending_pto_minutes', { employee_id: employeeId })
    if (error) return { error: error.message }
    else return { data }
  }

  async countTotalPendingMinutesForYear(employeeId: string, year: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('count_total_pending_pto_minutes_for_year', { employee_id: employeeId, year })
    if (error) return { error: error.message }
    else return { data }
  }

  async countTotalApprovedMinutesForYear(employeeId: string, year: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('count_total_approved_pto_minutes_for_year', { employee_id: employeeId, year })
    if (error) return { error: error.message }
    else return { data }
  }

  async approve(requestId: number, approverId: string): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('approve_pto_request', { pto_request_id: requestId, approver_id: approverId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }

  async deny(requestId: number, reviewerId: string, reason?: string): Promise<{ data: entityTypes.PTORequest[], error?: undefined } | { data?: undefined, error: string }> {
    reason = reason || 'No reason'
    const { data, error } = await supabase.rpc('deny_pto_request', { pto_request_id: requestId, reviewer_id: reviewerId, reason })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.PTORequest[] }
  }
}

export const ptoRequests = new PTORequestsAPI()

// Employee Expenses
class EmployeeExpensesAPI extends EntityAPI<entityTypes.EmployeeExpense, entityTypes.EmployeeExpenseInput, number, 'employee_expenses'> {
  constructor() {
    super('employee_expenses', 'id', '*, account:expense_accounts (*), projectTask:project_tasks (name), project:projects (name, number), projectPhase:project_phases ( phase:phases (name, abbr)), resource:project_phase_resources ( department:departments (name)), paymentMethod:expense_payment_methods (name)')
  }

  async getBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.EmployeeExpense[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('employee_expenses').select(this.selectString).eq('employee_id', employeeId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.EmployeeExpense[] }
  }

  async getIncludedBillableExpenses(): Promise<{ data: BillableExpenseByResource[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_billable_expenses_by_resource_and_expense_account', { pe_type: 'included' })
    if (error) return { error: error.message }
    else return { data: data as unknown as BillableExpenseByResource[] }
  }

  async getReimbursableBillableExpenses(): Promise<{ data: BillableExpenseByResource[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_billable_expenses_by_resource_and_expense_account', { pe_type: 'reimbursable' })
    if (error) return { error: error.message }
    else return { data: data as unknown as BillableExpenseByResource[] }
  }

  async unreview(id: number): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('unreview_employee_expense', { ee_id: id })
    if (error) return { error: error.message }
    else return {}
  }
}

export const employeeExpenses = new EmployeeExpensesAPI()

// Tenants
class TenantsAPI extends EntityAPI<entityTypes.Tenant, entityTypes.TenantInput, string, 'tenants'> {
  constructor() {
    super('tenants', 'id')
  }

  async switch(toTenantId: string): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('switch_tenant', { to_tenant_id: toTenantId })
    if (error) return { error: error.message }
    else return {}
  }
}

export const tenants = new TenantsAPI()

// Timesheets
class TimesheetsAPI extends EntityAPI<entityTypes.Timesheet, entityTypes.TimesheetInput, number, 'timesheets'> {
  constructor() {
    super('timesheets', 'id', `*,
    mte_allocations (*)`)
  }

  async getSubmittedBetweenDates(profileID: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.Timesheet[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('timesheets').select(this.selectString).eq('employee_id', profileID).gte('submitted_to', dateToString(startDate)).lte('submitted_to', dateToString(endDate))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Timesheet[] }
  }

  async getApprovedBetweenDates(profileID: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.Timesheet[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('timesheets').select(this.selectString).eq('employee_id', profileID).gte('approved_to', dateToString(startDate)).lte('approved_to', dateToString(endDate))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Timesheet[] }
  }

  async getSubmittedBetweenDatesForAllEmployees(startDate: Date, endDate: Date): Promise<{ data: entityTypes.Timesheet[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from('timesheets').select(this.selectString).gte('submitted_to', dateToString(startDate)).lte('submitted_to', dateToString(endDate))
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Timesheet[] }
  }

  async submitEverything(employeeId: string, fromDate: string, toDate: string, progressEstimates: entityTypes.ProjectPhaseResourceProgressEstimateInput[], expenseIdsToSubmit: number[], ptoMin?: number, payMin?: number): Promise<{ data: entityTypes.Timesheet, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('submit_complete_timesheet', {
      employee_id: employeeId,
      from_date: fromDate,
      to_date: toDate,
      // progress_estimates: JSON.stringify(progressEstimates), // TODO stringify gets rid of type error but is a breaking change
      progress_estimates: progressEstimates,
      expense_ids_to_submit: expenseIdsToSubmit,
      pto_min: ptoMin || 0,
      pay_min: payMin || 0
    })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Timesheet }
  }

  async approve(timesheetId: number, approverId: string): Promise<{ data: entityTypes.Timesheet[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('approve_timesheet', { timesheet_id: timesheetId, approver_id: approverId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Timesheet[] }
  }

  async reject(timesheetId: number): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('reject_timesheet', { timesheet_id: timesheetId })
    if (error) return { error: error.message }
    else return {}
  }

  /** Submit and Approve timesheet on behalf of an employee. */
  async submitAndApprove(employeeId: string, approverId: string, fromDate: string, untilDate: string, ptoMins?: number, payMins?: number): Promise<{ data: entityTypes.Timesheet[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('submit_and_approve_timesheet_for_employee', { emp_id: employeeId, approver_id: approverId, from_date: fromDate, until_date: untilDate, pto_mins: ptoMins, mte_mins: payMins })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Timesheet[] }
  }
}

export const timesheets = new TimesheetsAPI()

// Timesheet Entries
class TimesheetEntriesAPI extends EntityAPI<entityTypes.TimesheetEntry, entityTypes.TimesheetEntryInput, number, 'timesheet_entries'> {
  constructor() {
    // Add this to select string project_phase:project_phase_id section (if can get working): project_phase_resource_progress_estimates!inner(*),
    super('timesheet_entries', 'id', `*,
    timesheet_overhead:timesheet_overheads (*),
    project:projects (id, name, number, client:clients (name, account_no)),
    project_phase:project_phases (
      id,
      phase:phases (id, name, abbr),
      phase_group:project_phase_groups (id, name)
    ),
    project_phase_resource:project_phase_resources ( id, department:departments (name, id)),
    project_task:project_tasks (id, name, project_phase_resource_id),
    opp:opportunities (id, name, number, client:clients (name)),
    pto_request:pto_requests (*),
    fh_request:floating_holiday_requests (*)
    `)
  }

  async getBetweenDates(profileID: string, startDate: Date, endDate: Date): Promise<{ data: entityTypes.TimesheetEntry[], error?: undefined } | { data?: undefined, error: string }> {
    const user = useUserStore()
    if (!user.activeTenantId) return { error: 'No active tenant' }
    const { data, error } = await supabase.from('timesheet_entries').select(this.selectString).eq('employee_id', profileID).eq('tenant_id', user.activeTenantId).gte('date', dateToString(startDate)).lte('date', dateToString(endDate))
      .order('project_id', { ascending: true }).order('project_phase_id', { ascending: true }).order('project_phase_resource_id', { ascending: true })

    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.TimesheetEntry[] }
  }

  async createPtoTimeEntry(employeeId: string, timesheetOverheadId: number, date: Date, duration: number): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_pto_time_entry', {
      employee_id: employeeId,
      timesheet_overhead_id: timesheetOverheadId,
      date: dateToString(date),
      duration
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async createAndApprovePtoTimeEntry(employeeId: string, approverId: string, timesheetOverheadId: number, date: Date, duration: number): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_and_approve_pto_time_entry', {
      employee_id: employeeId,
      approver_id: approverId,
      timesheet_overhead_id: timesheetOverheadId,
      date: dateToString(date),
      duration
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async createPtoTimeEntryWithoutRequest(employeeId: string, approverId: string, timesheetOverheadId: number, date: Date, duration: number, timesheetId?: number): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_pto_time_entry_without_request', {
      employee_id: employeeId,
      approver_id: approverId,
      timesheet_overhead_id: timesheetOverheadId,
      date: dateToString(date),
      duration,
      timesheet_id: timesheetId
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async updatePtoTimeEntry(timesheetEntryId: number, duration: number): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('update_pto_time_entry', {
      timesheet_entry_id: timesheetEntryId,
      duration
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async updateAndApprovePtoTimeEntry(timesheetEntryId: number, approverId: string, duration: number): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('update_and_approve_pto_time_entry', {
      timesheet_entry_id: timesheetEntryId,
      approver_id: approverId,
      duration
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async deletePtoTimeEntry(timesheetEntryId: number): Promise<{ error?: undefined } | { error: string }> {
    const { error } = await supabase.rpc('delete_pto_time_entry', { timesheet_entry_id: timesheetEntryId })
    if (error) return { error: error.message }
    else return {}
  }

  async deleteFloatingHolidayTimeEntry(timesheetEntryId: number): Promise<{ data: string, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('delete_floating_holiday_time_entry', {
      timesheet_entry_id: timesheetEntryId
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as string }
  }

  async createFloatingHolidayTimeEntry(employeeId: string, timesheetOverheadId: number, date: Date): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_floating_holiday_time_entry', {
      employee_id: employeeId,
      timesheet_overhead_id: timesheetOverheadId,
      date: dateToString(date)
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async createAndApproveFloatingHolidayTimeEntry(employeeId: string, approverId: string, timesheetOverheadId: number, date: Date): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_and_approve_floating_holiday_time_entry', {
      employee_id: employeeId,
      approver_id: approverId,
      timesheet_overhead_id: timesheetOverheadId,
      date: dateToString(date)
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async createFloatingHolidayTimeEntryWithoutRequest(employeeId: string, approverId: string, timesheetOverheadId: number, date: Date, timesheetId?: number): Promise<{ data: entityTypes.TimesheetEntry, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('create_floating_holiday_time_entry_without_request', {
      employee_id: employeeId,
      approver_id: approverId,
      timesheet_overhead_id: timesheetOverheadId,
      date: dateToString(date),
      timesheet_id: timesheetId
    })
    if (error) return { error: error.message }
    else return { data: data[0] as unknown as entityTypes.TimesheetEntry }
  }

  async getTotalTimeBetweenDates(employeeId: string, startDate: Date, endDate: Date): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_total_time_entry_duration_between_dates', { employee_id: employeeId, start_date: dateToString(startDate), end_date: dateToString(endDate) })
    if (error) return { error: error.message }
    else return { data: data[0].total_duration }
  }

  async getTotalTimeForAllEmployeesBetweenDates(startDate: Date, endDate: Date): Promise<{ data: { employee_id: string, duration: number }[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_total_time_entry_durations_for_employees', { startdate: dateToString(startDate), enddate: dateToString(endDate) })
    if (error) return { error: error.message }
    else return { data }
  }
}

export const timesheetEntries = new TimesheetEntriesAPI()

// Notifications
class NotificationsAPI extends EntityAPI<entityTypes.Notification, entityTypes.NotificationInput, number, 'notifications'> {
  constructor() {
    super('notifications', 'id', '*')
  }

  async getForEmployee(employeeId: string): Promise<{ data: entityTypes.Notification[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select(this.selectString).eq('employee_id', employeeId)
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Notification[] }
  }

  async markAllAsRead(userId: string): Promise<{ data?: entityTypes.Notification[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).update({ status: NotificationStatus.Read }).eq('recipient_id', userId).select(this.selectString)
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Notification[] }
  }
}

export const notifications = new NotificationsAPI()

// Proposals
class ProposalAPI extends EntityAPI<entityTypes.Proposal, entityTypes.ProposalInput, number, 'proposals'> {
  constructor() {
    super('proposals', 'id', `*,
    sections:proposal_sections (id, title, text, delta, selected, order),
    addressee:contacts (*),
    marketing_contact:marketing_contact_id (*)`)
  }

  async downloadPDF(proposal: entityTypes.Proposal): Promise<{ data?: Blob, error?: string }> {
    if (!proposal.filename) return { error: 'No file to download' }
    const { data, error } = await storageApi.downloadFile('proposals', '', proposal.filename)
    if (!error && data) return { data: new Blob([data], { type: 'application/pdf' }) }
    else if (error) return { error: error.message }
    else return { error: 'Unknown error' }
  }
}

export const proposals = new ProposalAPI()

// Proposal Sections
class ProposalSectionAPI extends EntityAPI<entityTypes.ProposalSection, entityTypes.ProposalSectionInput, number, 'proposal_sections'> {
  constructor() {
    super('proposal_sections', 'id')
  }

  async hardDeleteAllFromProposal(proposalId: number): Promise<{ data?: entityTypes.ProposalSection[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).delete().eq('proposal_id', proposalId).select()
    if (error) return { error: error.message }
    else return { data }
  }
}

export const proposalSections = new ProposalSectionAPI()

// Proposal Types
class ProposalTypeAPI extends EntityAPI<entityTypes.ProposalType, entityTypes.ProposalTypeInput, number, 'proposal_types'> {
  constructor() {
    super('proposal_types', 'id')
  }

  async getAllWithSections(): Promise<{ data: entityTypes.ProposalTypeWithSections[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select('*, default_sections:proposal_default_sections (*)').order('order', { foreignTable: 'proposal_default_sections' })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.ProposalTypeWithSections[] }
  }
}

export const proposalTypes = new ProposalTypeAPI()

// Channels
class ChannelAPI extends EntityAPI<entityTypes.Channel, entityTypes.ChannelInput, number, 'channels'> {
  constructor() {
    super('channels', 'id', '*, memberships:channel_memberships ( id, employee_id )')
  }

  async subscribe(channelId: number, messageCallback: (message: ChannelMessage) => void, statusCallback: (status: string) => void): Promise<void> {
    const user = useUserStore()
    if (!user.activeTenant) return
    supabase.channel('channel_messages').on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'channel_messages_' + user.activeTenant.code, filter: `channel_id=eq.${channelId}` },
      (payload) => {
        console.log('message received', payload.new as ChannelMessage)
        messageCallback(payload.new as ChannelMessage)
      }
    ).subscribe(async (status) => {
      statusCallback(status)
    })
  }

  async getApproverChannelForProject(projectId: number): Promise<{ data?: entityTypes.Channel, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select(this.selectString).eq('type', 'approval').eq('project_id', projectId).single()
    if (error) return { error: error.message }
    else if (data) return { data: data as unknown as entityTypes.Channel }
    else return { error: 'Unknown error' }
  }

  async addApproverChannelForProject(projectId: number, employeeId: string): Promise<{ data?: entityTypes.Channel, error?: undefined } | { data?: undefined, error: string }> {
    const user = useUserStore()
    const newChannel: entityTypes.ChannelInput = new entityTypes.ChannelInput({
      name: 'Approval Chat',
      description: 'Approver Chat for Project ' + projectId,
      is_public: false,
      owner_id: employeeId,
      tenant_id: user.activeTenantId
    })
    newChannel.type = 'approval'
    newChannel.project_id = projectId
    const { data, error } = await supabase.from(this.tableName).insert(newChannel).select(this.selectString).single()
    if (error) return { error: error.message }
    else if (data) return { data: data as unknown as entityTypes.Channel }
    else return { error: 'Unknown error' }
  }

  async removeApproverChannelForProject(projectId: number): Promise<{ data?: entityTypes.Channel, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await this.getApproverChannelForProject(projectId)
    if (!data || error) return { error: 'No channel found' }
    else if (data) return await this.hardDelete(data.id)
    else return { error: 'Unknown error' }
  }
}
export const channels = new ChannelAPI()

// Channel Messages
class ChannelMessagesAPI extends EntityAPI<entityTypes.ChannelMessage, entityTypes.ChannelMessageInput, number, 'channel_messages'> {
  constructor() {
    super('channel_messages', 'id')
  }

  async send(channelId: number, message: string, color?: string): Promise<{ data: entityTypes.ChannelMessage, error?: undefined } | { data?: undefined, error: string }> {
    const user = useUserStore()
    let messageToSend = message.trim()
    messageToSend = messageToSend.replace(/(\r?\n|\r)/gm, '')
    messageToSend = messageToSend.replace(/<p>&nbsp;<\/p>$/gm, '')
    const regex = /<p><br><\/p>$/
    while (regex.test(messageToSend)) {
      messageToSend = messageToSend.replace(/<p><br><\/p>$/gm, '')
    }
    const regexB = /<p><br><\/p>/
    while (regexB.test(messageToSend)) {
      messageToSend = messageToSend.replace(/<p><br><\/p>/gm, '<br>')
    }
    while (regex.test(messageToSend)) {
      messageToSend = messageToSend.replace(/<p><br><\/p>$/gm, '')
    }
    const { data, error } = await this.add(new ChannelMessageInput({
      channel_id: channelId,
      employee_id: user.id,
      message: messageToSend,
      color
    }))
    if (error) return { error }
    else return { data: data as unknown as entityTypes.ChannelMessage }
  }
}

export const channelMessages = new ChannelMessagesAPI()

// Todos
class TodoAPI extends EntityAPI<entityTypes.Todo, entityTypes.TodoInput, number, 'todos'> {
  constructor() {
    super('todos', 'id', '*, project:projects (id, name)')
  }

  async getCompleted(userId: string, start?: number, end?: number): Promise<{ data: entityTypes.Todo[], error?: undefined } | { data?: undefined, error: string }> {
    if (typeof start !== 'undefined' && typeof end !== 'undefined') {
      const { data, error } = await supabase.from('todos').select(this.selectString).eq('assigned_to', userId).not('completed_at', 'is', null).range(start, end).order('due_date')
      if (error) return { error: error.message }
      else return { data: data as unknown as entityTypes.Todo[] }
    }
    else {
      const { data, error } = await supabase.from('todos').select(this.selectString).eq('assigned_to', userId).not('completed_at', 'is', null).order('due_date')
      if (error) return { error: error.message }
      else return { data: data as unknown as entityTypes.Todo[] }
    }
  }

  async getIncomplete(userId: string, start?: number, end?: number): Promise<{ data: entityTypes.Todo[], error?: undefined } | { data?: undefined, error: string }> {
    if (typeof start !== 'undefined' && typeof end !== 'undefined') {
      const { data, error } = await supabase.from('todos').select(this.selectString).eq('assigned_to', userId).is('completed_at', null).range(start, end).order('due_date')
      if (error) return { error: error.message }
      else return { data: data as unknown as entityTypes.Todo[] }
    }
    else {
      const { data, error } = await supabase.from('todos').select(this.selectString).eq('assigned_to', userId).is('completed_at', null).order('due_date')
      if (error) return { error: error.message }
      else return { data: data as unknown as entityTypes.Todo[] }
    }
  }

  async complete(todoId: number) {
    return await this.update(todoId, { completed_at: currentDateAsString() })
  }

  async restore(todoId: number) {
    return await this.update(todoId, { completed_at: null })
  }
}

export const todos = new TodoAPI()

// Profiles
class ProfileAPI extends EntityAPI<entityTypes.Profile, entityTypes.ProfileInput, string, 'profiles'> {
  constructor() {
    super('profiles', 'id', '*, roles:user_roles(*)')
  }

  async getAvatar(profile: entityTypes.Profile): Promise<{ data: Blob, error?: undefined } | { data?: undefined, error: string }> {
    if (!profile.avatar_url) return { error: 'No avatar url' }
    const { data, error } = await supabase.storage.from('avatars').download(profile.avatar_url)
    if (error) return { error: error.message }
    else return { data }
  }

  async getSignature(profile: entityTypes.Profile): Promise<{ data: Blob, error?: undefined } | { data?: undefined, error: string }> {
    if (!profile.signature_url) return { error: 'No signature url' }
    // const user = useUserStore()
    let sigUrl = profile.signature_url
    // if sigUrl doesn't start with "profiles/" then add it
    if (!sigUrl.startsWith('profiles/')) {
      sigUrl = 'profiles/' + sigUrl
    }
    const { data, error } = await supabase.storage.from(profile.tenant_id).download(sigUrl)
    if (error) return { error: error.message }
    else return { data }
  }

  async getSignatureUrl(profile: entityTypes.Profile): Promise<{ data: string, error?: undefined } | { data?: undefined, error: string }> {
    if (!profile.signature_url) return { error: 'No signature url' }
    const signatureBlob = await this.getSignature(profile)
    if (signatureBlob.error) return { error: signatureBlob.error }
    if (!signatureBlob.data) return { error: 'No signature data' }
    return { data: URL.createObjectURL(signatureBlob.data), error: undefined }
  }

  async getWithEmployee(profileId: string): Promise<{ data: entityTypes.ProfileWithEmployee, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select(this.selectString + ', employee:employees_id_fkey (*)').eq('id', profileId).single()
    if (error) return { error: error.message }
    else if (data) {
      return { data: data as unknown as entityTypes.ProfileWithEmployee }
    }
    else return { error: 'Unknown error' }
  }
}

export const profiles = new ProfileAPI()

// Projects

class ProjectsAPI extends EntityAPI<entityTypes.Project, entityTypes.ProjectInput, number, 'projects'> {
  constructor() {
    super('projects', 'id', `*,
    client:clients (*, contacts:contacts (*)),
    opportunity:opportunities (name, asr_version),
    projects (*),
    phaseGroups:project_phase_groups (*,
      phases:project_phases (*,
        phase:phases (*)
      )
    ),
    projectPhases:project_phases (*,
      resources:project_phase_resources (*,
        bid_requests:bid_requests (*),
        department:departments (*),
        pr_percent_completes (*),
        tasks:project_tasks (*,
          estimates:project_task_estimates (
            employee:employee_id,
            fee,
            hours,
            hourly_rate,
            comments,
            created_at
          ),
          expenses:project_task_expenses (*),
          exceptions:project_task_exceptions (*),
          clarifications:project_task_clarifications (*, employee:employee_id (department_id, profile:profiles!employees_id_fkey (name)))
        ),
        prior_billed_expenses (*)
      ),
      phase:phases (*)
    ),
    expenses:project_expenses (
      id,
      project_id,
      name,
      cost,
      markup_percentage,
      employee_id,
      employee:employee_id (
        department_id,
        profile:profiles!employees_id_fkey (*)
      ),
      created_at
    )`, [EntityAPIFeatures.fts, EntityAPIFeatures.softDelete])
  }

  async getChildren(projectId: number): Promise<{ data: entityTypes.Project[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select(this.selectString).eq('parent_project_id', projectId)
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Project[] }
  }

  async getMinutesChargedSinceLastInvoiced(projectId: number, pprId: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_minutes_charged_since_last_invoiced', { project_id: projectId, ppr_id: pprId })
    if (error) return { error: error.message }
    else return { data }
  }

  async getExpensesIncurredSinceLastInvoiced(projectId: number, pprId: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_expenses_incurred_since_last_invoiced', { project_id: projectId, ppr_id: pprId })
    if (error) return { error: error.message }
    else return { data }
  }

  // CREATE OR REPLACE FUNCTION get_projects_for_principal_id(employee_id UUID)
  async getWherePrincipalInCharge(employeeId: string): Promise<{ data: entityTypes.Project[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_projects_for_principal_id', { employee_id: employeeId })
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Project[] }
  }

  async getForTask(projectTaskId: number): Promise<{ data: entityTypes.Project, error?: undefined } | { data?: undefined, error: string }> {
    type ResultFromGetForTask = { project_phase_resources: { project_phase_id: number, project_phases: { project_id: number, project: entityTypes.Project } } }
    const { data, error } = await supabase.from('project_tasks').select(`
      project_phase_resources:project_phase_resource_id (
        project_phase_id,
        project_phases:project_phase_id (
          project_id,
          project:project_id (${this.selectString})
        )
      )
    `).eq('id', projectTaskId)
    if (error) return { error: error.message }
    else if (data) {
      if (data.length) {
        const result = data[0] as unknown as ResultFromGetForTask
        return { data: result.project_phase_resources.project_phases.project }
      } else return { error: 'No data found' }
    } else return { error: 'Unknown error' }
  }

  async getForResource(projectResourceId: number): Promise<{ data: entityTypes.Project, error?: undefined } | { data?: undefined, error: string }> {
    type ResultFromGetForResource = { project_phase_id: number, project_phases: { project_id: number, project: entityTypes.Project } }
    const { data, error } = await supabase.from('project_phase_resources').select(`
      project_phase_id,
      project_phases:project_phases (
        project_id,
        project:projects (${this.selectString})
      )
    `).eq('id', projectResourceId)
    if (error) return { error: error.message }
    else if (data) {
      if (data.length) {
        const result = data[0] as unknown as ResultFromGetForResource
        return { data: result.project_phases.project }
      } else return { error: 'No data found' }
    } else return { error: 'Unknown error' }
  }

  async getPreBillingReviewStatus(projectId: number): Promise<{ data: PRBillingReviewStatus, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.rpc('get_pre_bill_resource_status_counts', { pid: projectId })
    if (error || !data) return { error: error.message }
    else return { data: data[0] as PRBillingReviewStatus }
  }

  async getForPhase(projectPhaseId: number): Promise<{ data: entityTypes.Project, error?: undefined } | { data?: undefined, error: string }> {
    type ResultFromGetForPhase = { project_id: number, project: entityTypes.Project }
    const { data, error } = await supabase.from('project_phases').select(`
      project_id,
      project:project_id (${this.selectString})
    `).eq('id', projectPhaseId)
    if (error) return { error: error.message }
    else if (data) {
      if (data.length) {
        const result = data[0] as unknown as ResultFromGetForPhase
        return { data: result.project }
      } else return { error: 'No data found' }
    } else return { error: 'Unknown error' }
  }
}
export const projects = new ProjectsAPI()

// Opps
class OppsAPI extends EntityAPI<entityTypes.Opp, entityTypes.OppInput, number, 'opportunities'> {
  constructor() {
    super('opportunities', 'id', `*,
    client:clients (*),
    lead:lead_id ( profile:profiles!employees_id_fkey ( * ) ),
    approvals:opp_approvals (*, approver:approvers (*)),
    remarks:opp_remarks (*),
    parentOpp:opportunities (*),
    clarifications:opp_clarifications (id, clarification_id)
    `, [EntityAPIFeatures.softDelete, EntityAPIFeatures.fts])
  }

  async forClient(accountNo: number): Promise<{ data: entityTypes.Opp[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select(this.selectString).eq('client_account_no', accountNo)
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Opp[] }
  }

  async getNextASRVersion(oppId: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    // We need to get the asr_version field for every record in the opportunities table where the parent_opportunity_id = oppId
    // Then we need to get the max of those values and add 1 to it
    const { data, error } = await supabase.from(this.tableName).select('asr_version').eq('parent_opportunity_id', oppId)
    if (error) return { error: error.message }
    else if (data) {
      if (data.length === 0) return { data: 1 }
      const asrVersions = data.map((opp) => opp.asr_version)
      const maxASRVersion = Math.max(...asrVersions)
      return { data: maxASRVersion + 1 }
    } else return { error: 'Unknown error' }
  }

  async getChildren(oppId: number): Promise<{ data: entityTypes.Opp[], error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select(this.selectString).eq('parent_opportunity_id', oppId)
    if (error) return { error: error.message }
    else return { data: data as unknown as entityTypes.Opp[] }
  }
}

export const opps = new OppsAPI()

// Clients
class ClientAPI extends EntityAPI<entityTypes.Client, entityTypes.ClientInput, number, 'clients'> {
  constructor() {
    super('clients', 'account_no', '*, contacts:contacts (*)', [EntityAPIFeatures.softDelete, EntityAPIFeatures.fts])
  }

  async getAddress(accountNumber: number): Promise<{ data: Address | null, error?: undefined } | { data?: undefined, error?: string }> {
    const { data, error } = await supabase.from(this.tableName).select('address, address2, city, state, zip').eq('account_no', accountNumber).single()
    if (!error && data) return { data: data as Address }
    else if (error) return { error: error.message }
    else return { error: 'No data found' }
  }

  async getFull(accountNumber: number): Promise<{ data: entityTypes.Client | null, error?: undefined } | { data?: undefined, error?: string }> {
    const fullSelectString = `*,
    contacts:contacts (*),
    projects:projects (*),
    opportunities:opportunities (*,
      client:clients (*),
      lead:lead_id ( profile:profiles!employees_id_fkey ( * ) )
    )`

    const { data, error } = await supabase.from(this.tableName).select(fullSelectString).eq('account_no', accountNumber).single()
    if (!error && data) return { data: data as unknown as entityTypes.Client }
    else if (error) return { error: error.message }
    else return { error: 'No data found' }
  }

  async getLastAccountNoForIndustry(industryCode: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const { data, error } = await supabase.from(this.tableName).select('account_no')
      .gte('account_no', industryCode)
      .lte('account_no', industryCode + 999)
    if (!error && data) {
      if (data.length) {
        data.sort((a: { account_no: string }, b: { account_no: string }) => {
          if (a.account_no > b.account_no) return -1
          else if (a.account_no < b.account_no) return 1
          else return 0
        })
        return { data: Number(data[0].account_no) + 1 }
      } else return { data: Number(industryCode) + 1 }
    } else return { error: error.message }
  }

  async getNextAccountNoForIndustry(industryCode: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    // use getLastAccountNoForIndustry to get the last account number for the industry and add 1 to it
    const { data, error } = await this.getLastAccountNoForIndustry(industryCode)
    if (data) return { data: data as number }
    else if (error) return { error }
    else return { error: 'No data found' }
  }

  async getNextOppNoForClient(accountNo: number): Promise<{ data: number, error?: undefined } | { data?: undefined, error: string }> {
    const currentYearSuffix = new Date().getFullYear().toString().slice(-2) // 21
    const { data, error } = await supabase.from('projects').select('number').eq('client_account_no', accountNo).ilike('number', `%${currentYearSuffix}`).order('number', { ascending: false }).limit(1)
    if (!error && data) {
      if (data.length === 0) return { data: Number(`${accountNo}01${new Date().getFullYear().toString().slice(-2)}`) }
      const lastOppNumber = data[0].number
      const currentYear = new Date().getFullYear().toString().slice(-2)
      const lastOppYear = lastOppNumber.toString().slice(-2)
      const lastOppJobNumber = Number(lastOppNumber.toString().slice(-4, -2))
      const nextOppJobNumber = (lastOppJobNumber + 1).toString().padStart(2, '0')
      if (lastOppYear === currentYear) {
        return { data: Number(`${accountNo}${nextOppJobNumber}${currentYear.toString().slice(-2)}`) }
      } else {
        return { data: Number(`${accountNo}01${currentYear.toString().slice(-2)}`) }
      }
    } else return { data: Number(`${accountNo}01${new Date().getFullYear().toString().slice(-2)}`) }
  }
}

export const clients = new ClientAPI()
